@import './shared.scss';

$background-height: 75vh;
$header-title: 56px;

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.about-me-page .background-container {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../public/images/page-backgrounds/About-Me-Background-Image.jpg");
}

.background {
  background-color: #4e54c8;
  height: $background-height;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.background-container {
  align-items: center;
  background-attachment: scroll, fixed;
  background-color: #000;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../public/images/page-backgrounds/Home-Background-Image.jpg");
  background-position: 0px, 0px, 50%, 100%;
  background-size: auto, cover;
  flex-wrap: nowrap;
  justify-content: center;
  min-height: 75vh;
  padding: 100px 5% 65px;
}

.breadcrumb-item a {
  color: $text-color !important;
  text-decoration: none !important;
}

.breadcrumb-item a:hover {
  color: $nav-link-text-color !important;
}

.btn-close {
  background-color: white !important;
}

.btn-danger {
  background-color: $danger-1 !important;
  border: $danger-1 !important;
  color: white !important;
}

.btn-danger:hover {
  background-color: $danger-2 !important;
  border: $danger-2 !important;
  color: white !important;
}

.btn-info {
  background-color: $info-1 !important;
  border: $info-1 !important;
  color: black !important;
}

.btn-info:hover {
  background-color: $info-2 !important;
  border: $info-2 !important;
  color: white !important;
}

.breadcrumb-item {
  a {
    color: $text-color !important;
  }
}

.contact-form {
  margin-left: auto;
  margin-right: auto;
  max-width: 420px;
}

.contact-us-page .section-background {
  padding: 11% 5%;
}

.contact-us-page .background-container {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../public/images/page-backgrounds/Contact-Me-Background-Image.jpg");
}

.header {
  left: 40%;
  position: absolute;
  top: 40%;
  transform: translate(-40%, -40%);
  width: 100%;
  z-index: 1;
}

.header .title {
  font-size: 3.5em;
}

@media (max-width: 1200px) {
  .header .title {
    font-size: 1.1rem;
  }
}

.header-container {
  height: 450px;
  position: relative;
}

@media (max-width: 768px) {
  .header-container {
    height: 200px;
  }
}

.header-container img {
  height: 100%;
  position: relative;
  z-index: 1;
}

.navigation-menu.scrolling {
  box-shadow: 0 0 .5em rgba(0, 0, 0, .5);
}

.nav-link {
  color: $text-color !important;
}

.nav-link:hover {
  color: $nav-link-text-color !important;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.69);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}

.page-footer {
  a {
    color: $text-color;
  }
}

.projects-page .background-container {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../public/images/page-backgrounds/Projects-Background-Image.jpg");
}

.skill-badge {
  background-color: $primary-1;
  color: white;
}

.text-secondary {
  color: $secondary-1 !important;
}

.top-button {
  align-items: center;
  background-color: "red";
  border-radius: 100%;
  bottom: 10px;
  display: flex;
  height: 55px;
  justify-content: center;
  left: auto;
  position: fixed;
  right: 10px;
  top: auto;
  transition: all 300ms ease;
  width: 55px;
}

.top-button img {
  filter: invert(100%);
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .background-container {
    background-position: 0px, 50%, 50%, 100%;
  }
}

.bg-danger {
  background-color: $danger-1 !important;
}

.bg-info {
  background-color: $primary-1 !important;
}

.bg-primary {
  background-color: $primary-color !important;
}

.bg-secondary {
  background-color: $purple-1 !important;
}

.bg-success {
  background-color: $success-1 !important;
}

.navigation-menu.scrolling {
  box-shadow: 0 0 .5em rgba(0, 0, 0, .5);
}

.page-footer {
  a {
    color: $text-color;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .background-container {
    background-position: 0px, 50%, 50%, 100%;
  }
}


.text-transition {
  height: 84px !important;
  width: auto !important;
}

@media (max-width: 1200px) {
  .text-transition {
    height: 24px !important;
  }
}