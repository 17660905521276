// Shared SCSS

// Fonts
$font-family: 'Helvetica', sans-serif;
$font-size: 16px;

// Colors
$primary-color: #0E1577;
$secondary-color: #f2f2f2;

$text-color: #FFFFFF;
$nav-link-text-color: #CCCCCC;

// Black shades
$black: #000000;

// Secondary shades
$secondary-1: #CCCCCC;

// Purple shades
$purple-1: #602297;

// Tuna shades
$tuna-1: #333646;
$tuna-2: #22242F;
$tuna-3: #101118;

// Neutral shades
$neutral-1: #373637;
$neutral-2: #252425;
$neutral-3: #070607;

// Success shades
$success-1: #0B6E4F;
$success-2: #0f2a01;
$success-3: #0a1403;

// Caution shades
$caution-1: #e7be48;
$caution-2: #2b230d;
$caution-3: #151107;

// Danger shades
$danger-1: #de123b;
$danger-2: #8B0000;
$danger-3: #240904;

// Info shades
$info-1: #0085EF;
$info-2: #0049A8;
$info-3: #071418;

// Primary shades
$primary-1: #4E5A9D;
$primary-2: #0E1577;
$primary-3: #0B1063;

// Blue ribbon shades
$blue-ribbon-1: #4b4be8;
$blue-ribbon-2: #191a67;
$blue-ribbon-3: #0f0b38;

// Ultramarine shades
$ultramarine-1: #3c1bff;
$ultramarine-2: #150596;
$ultramarine-3: #130444;